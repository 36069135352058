import {graphql, useStaticQuery} from 'gatsby';
import {Strapi_Films} from '../../typegen/graphql';

const useSeriesData = () => {
  const data = useStaticQuery(graphql`
  query {
    strapi {
      films(sort: "date:DESC") {
        ...Film
      }
    }
  }
`);

  return data.strapi.films as Strapi_Films[];
};

export default useSeriesData;
