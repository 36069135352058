import React from 'react';
import {PageContent} from '../components/Layout';
import {ThumbnailContainer, Appear} from '../components/atoms';
import {getImage} from 'gatsby-plugin-image';
import Thumbnail from '../components/Thumbnail';
import styled from 'styled-components';
import useFilmData from '../queries/useFilmData';
import {useLocation} from '@reach/router';
import {NavigationContext} from '../services/navigation';
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from 'gatsby';
import {getYear} from '../utils/getYear';

const Container = styled(PageContent)`
  display: flex;
`;

const Films: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const {bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        publicURL
      }
    }
  `)
  const {state} = useLocation();
  const allFilms = useFilmData();
  return (
    <Container>
      <Helmet
        title={`Films | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: 'Films for which Stavros Makronis composed the music',
          },
          {
            name: 'keywords',
            content: 'film, score, music, Stavros Markonis',
          },
          {
            property: `og:title`,
            content: `Films | Stavros Markonis`,
          },
          {
            property: `og:description`,
            content: 'Films for which Stavros Makronis composed the music',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Films | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content:  'Films for which Stavros Makronis composed the music',
          },
        ]}
      />
      <Appear>
        <ThumbnailContainer translation={(state as any)?.enableTranslationTransition}>
          {allFilms.map(film => (
            <Thumbnail
            key={film.id}
            image={getImage((film.thumbnail as any).file)}
            title={`${film.title}`}
            date={getYear(film)}
            to={film.slug}
          />
          ))}
        </ThumbnailContainer>
      </Appear>
    </Container>
  );
}

export default Films;
